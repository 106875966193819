import { defineStore } from 'pinia';
import { CtxOrderDto } from '@containex/portal-backend-dto';

interface CtxOrdersState {
    orders: CtxOrderDto[];
    totalOrderAmount: number;
}

export const useCtxOrderStore = defineStore('ctxOrders', {
    state: (): CtxOrdersState => ({
        orders: [],
        totalOrderAmount: 0,
    }),
});
