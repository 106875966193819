import { computed, type ComputedRef } from 'vue';
import { CtxOrderDto, PaginationStep } from '@containex/portal-backend-dto';
import { useCtxOrderStore } from '../stores/ctx-orders';
import { ctxOrdersApi } from '@containex/portal-backend-api-client';
import { httpClient } from '@/common/api/http-client';

export interface CtxOrderQuery {
    orders: ComputedRef<CtxOrderDto[]>;
    totalOrderAmount: ComputedRef<number>;
}

export interface CtxOrderAction {
    fetchOrders(orderAmount: number, step: PaginationStep): Promise<void>;
}

export function useCtxOrderQuery(): CtxOrderQuery {
    const store = useCtxOrderStore();

    return {
        orders: computed(() => store.orders),
        totalOrderAmount: computed(() => store.totalOrderAmount),
    };
}

export function useCtxOrderAction(): CtxOrderAction {
    const store = useCtxOrderStore();

    return {
        async fetchOrders(orderAmount: number, step: PaginationStep): Promise<void> {
            const orders = store.orders;
            let pageCursor;

            if (step === PaginationStep.New) {
                pageCursor = null;
            } else {
                const filterOrder = step === PaginationStep.Next ? orders[orders.length - 1] : orders[0];
                pageCursor = {
                    startDate: filterOrder?.date.toLocaleString(),
                    startId: filterOrder?.id,
                };
            }

            const response = await ctxOrdersApi.fetchCtxOrders(httpClient, {
                orderAmount: String(orderAmount),
                step,
                pageCursorDate: pageCursor?.startDate,
                pageCursorId: pageCursor?.startId,
            });

            store.orders = response.data.orders;
            store.totalOrderAmount = response.data.totalOrderAmount;
        },
    };
}
